import Flicking from '@egjs/react-flicking';
import ArticleCardItem from '@/components/ckk/items/ArticleCardItem';
import { Article } from '@/types/ckk';
interface Props {
  className?: string;
  articles: Article[];
}
function ArticleCardFlicking({
  articles
}: Props) {
  return <Flicking bound hideBeforeInit horizontal preventClickOnDrag align="prev" moveType="snap" data-sentry-element="Flicking" data-sentry-component="ArticleCardFlicking" data-sentry-source-file="ArticleCardFlicking.tsx">
      {articles.map((article, index) => <ArticleCardItem key={article.id} article={article} isFirst={index === 0} isLast={index === articles.length - 1} />)}
    </Flicking>;
}
export default ArticleCardFlicking;