import Link from 'next/link';
import { useRouter } from 'next/router';
import { forwardRef } from 'react';
import { faEye, faComment, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tag } from 'antd';
import ContentItem from '@/components/ckk/items/ContentItem';
import ProfileItem from '@/components/ckk/items/ProfileItem';
import { DECK_ARTICLE_CATEGORY_MAP, DeckArticle } from '@/types/ckk/deck';
interface Props {
  deckArticle: DeckArticle;
  isFirst?: boolean;
  isLast?: boolean;
}
function DeckArticleCardItem({
  deckArticle,
  isLast
}: Props, ref) {
  const {
    locale
  } = useRouter();
  const {
    category
  } = deckArticle;
  return <div ref={ref} data-sentry-component="DeckArticleCardItem" data-sentry-source-file="DeckArticleCardItem.tsx">
      <Link key={deckArticle.id} className={`ml-4 ${isLast ? 'mr-4' : '-mr-1'} flex rounded-lg bg-white px-4 py-3 shadow-lg`} href={{
      pathname: '/ckk/community/deck/detail/[articleId]',
      query: {
        articleId: deckArticle.id
      }
    }} data-sentry-element="Link" data-sentry-source-file="DeckArticleCardItem.tsx">
        <div className="flex w-full flex-col items-start gap-2">
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center justify-center">
              <Tag color="yellow" data-sentry-element="Tag" data-sentry-source-file="DeckArticleCardItem.tsx">
                <ContentItem content={deckArticle.content} withLink={false} data-sentry-element="ContentItem" data-sentry-source-file="DeckArticleCardItem.tsx" />
              </Tag>
              {category && <Tag color="green">
                  {DECK_ARTICLE_CATEGORY_MAP[category].emoji}{' '}
                  {DECK_ARTICLE_CATEGORY_MAP[category].title[locale]}
                </Tag>}
            </div>
            <div className="flex gap-2 text-xs font-light text-gray-700">
              <span>
                <FontAwesomeIcon icon={faEye} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="DeckArticleCardItem.tsx" /> {deckArticle?.readCount ?? 0}
              </span>
              <span>
                <FontAwesomeIcon icon={faComment} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="DeckArticleCardItem.tsx" />{' '}
                {deckArticle?.comments?.length ?? 0}
              </span>
              <span>
                <FontAwesomeIcon className="mr-1" icon={faThumbsUp} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="DeckArticleCardItem.tsx" />
                {deckArticle.upvote?.length ?? 0}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-2">
            <ProfileItem displayName={deckArticle?.author?.displayName} photoURL={deckArticle?.author?.photoURL} size="sm" uid={deckArticle?.author?.uid} withDisplayName={false} withLink={false} data-sentry-element="ProfileItem" data-sentry-source-file="DeckArticleCardItem.tsx" />
            <div className="w-44 truncate text-sm text-black">
              {deckArticle.title[locale]}
            </div>
          </div>
        </div>
      </Link>
    </div>;
}
export default forwardRef(DeckArticleCardItem);