import { QueryConstraint } from 'firebase/firestore'

import { BaseComment, IComment, PostType } from '@/types/ckk'
import {
  deleteCollectionDoc,
  getCollection,
  updateCollectionDoc,
} from '@/utils/firebase'
import { readProfileByUid } from '@/utils/firebase/profile'
interface PromiseFulfilledResult<T> {
  status: 'fulfilled'
  value: T
}

export const COMMENT_PAGE_SIZE = 5

const commentCollectionRef = getCollection('comments')

export const readComments = async ({
  uid,
  postType,
  postId,
}: {
  uid?: string
  postType?: PostType
  postId?: string
}): Promise<IComment[]> => {
  const { query, where, getDocs, orderBy } = await import('firebase/firestore')
  const baseComments: BaseComment[] = []

  const queryConstraints: QueryConstraint[] = []

  if (uid) {
    queryConstraints.push(where('author.uid', '==', uid))
  }

  if (postType) {
    queryConstraints.push(where('postType', '==', postType))
  }

  if (postId) {
    queryConstraints.push(where('postId', '==', postId))
  }
  queryConstraints.push(orderBy('createdAt', 'asc'))

  const q = query(commentCollectionRef, ...queryConstraints)

  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((_doc) => {
    const baseComment = { id: _doc.id, ..._doc.data() } as BaseComment
    baseComments.push(baseComment)
  })

  const comments = (
    await Promise.allSettled(
      baseComments.map(async (comment) => {
        const profile = await readProfileByUid({ uid: comment.author.uid })

        if (!profile) {
          // eslint-disable-next-line no-console
          console.log(`${comment.id} 댓글의 프로필을 찾을 수 없습니다.`)
          return Promise.reject('Profile not found')
        }

        return Promise.resolve({
          ...comment,
          author: profile,
        })
      }),
    )
  )
    .filter(({ status }) => status === 'fulfilled')
    .map((result: PromiseFulfilledResult<IComment>) => result.value)

  return comments
}

export const readPagedComments = async ({
  uid,
  postType,
  postId,
  createdAt,
  pageSize = COMMENT_PAGE_SIZE,
}: {
  uid?: string
  postType?: PostType
  postId?: string
  createdAt?: string
  pageSize?: number
}): Promise<IComment[]> => {
  const { query, where, getDocs, orderBy, limit, startAfter } = await import(
    'firebase/firestore'
  )

  const commentsWithKey: BaseComment[] = []
  const queryConstraints: QueryConstraint[] = []

  if (uid) {
    queryConstraints.push(where('author.uid', '==', uid))
  }
  if (postType) {
    queryConstraints.push(where('postType', '==', postType))
  }
  if (postId) {
    queryConstraints.push(where('postId', '==', postId))
  }
  queryConstraints.push(orderBy('createdAt', 'desc'))
  if (createdAt) {
    queryConstraints.push(startAfter(createdAt))
  }
  queryConstraints.push(limit(pageSize))

  const q = query(commentCollectionRef, ...queryConstraints)

  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((_doc) => {
    const commentWithKey = { id: _doc.id, ..._doc.data() } as BaseComment
    commentsWithKey.push(commentWithKey)
  })

  const comments = await Promise.all(
    commentsWithKey.map(async (comment) => {
      const profile = await readProfileByUid({ uid: comment.author.uid })
      return {
        ...comment,
        author: profile,
      }
    }),
  )

  return comments
}

export const updateBaseComment = updateCollectionDoc<BaseComment>({
  collectionRef: commentCollectionRef,
})

export const deleteBaseComment = deleteCollectionDoc({
  collectionRef: commentCollectionRef,
})
