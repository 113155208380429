import Link from 'next/link';
import { useRouter } from 'next/router';
import { forwardRef } from 'react';
import Img from '@/components/common/Img';
import { Reward, Size } from '@/types/shared';
import { getStyleFromSize } from '@/utils/size';
type Props = {
  reward: Reward;
  withLink?: boolean;
  withText?: boolean;
  withAmount: boolean;
  size?: Size;
  style?: React.CSSProperties;
  className?: string;
  locale?: string;
};
function RewardItem({
  reward: {
    currency: {
      name,
      image
    },
    amount
  },
  withLink = true,
  withText = false,
  withAmount,
  size = 'xs',
  style,
  className,
  locale: localeProps
}: Props, ref) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const locale = localeProps ?? useRouter().locale;
  const localeName = name[locale];
  const renderImage = () => {
    return <span className="item-image" style={style} title={localeName} data-sentry-component="renderImage" data-sentry-source-file="RewardItem.tsx">
        {!withText && <span className="absolute text-[0px]">{localeName}</span>}
        <Img alt={localeName} src={image} style={{
        ...getStyleFromSize(size)
      }} data-sentry-element="Img" data-sentry-source-file="RewardItem.tsx" />
      </span>;
  };
  const renderText = () => {
    return <span className={`flex w-full items-center justify-between text-xs ${withText ? 'relative ml-1 text-left text-inherit' : 'absolute -bottom-[0.1rem] right-0 text-right text-[0px]'}`} data-sentry-component="renderText" data-sentry-source-file="RewardItem.tsx">
        {withText && <span className=" ">{localeName}</span>}
        {withAmount && !!amount && (withText ? <span className="float-right ml-1">
              {amount?.toLocaleString()}
              {locale === 'ko' ? '개' : ''}
            </span> : <span className="ckkBold outlined text-sm">
              {amount >= 1000 ? `${amount / 1000}K` : amount}
            </span>)}
      </span>;
  };
  return withLink ? <Link href={`/ckk/currency/detail/[name]?name=${name.en}`} title={localeName}>
      <div ref={ref} className={`item-image ${className}`}>
        {renderImage()}
        {(withText || withAmount) && renderText()}
      </div>
    </Link> : <div ref={ref} className={`item-image ${className}`}>
      {renderImage()}
      {(withText || withAmount) && renderText()}
    </div>;
}
export default forwardRef(RewardItem);